import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, Tab, Tabs } from '@mui/material';
import getSymbolFromCurrency from 'currency-symbol-map';
import { format } from 'date-fns';

import CampaignFacebook from '@/components/campaigns-page/CampaignFacebook';
import CampaignInstagram from '@/components/campaigns-page/CampaignInstagram';
import CampaignSpotify from '@/components/campaigns-page/CampaignSpotify';
import CampaignSocialMediaTikTokReport from '@/components/campaigns-page/CampaignsSocialMediaTikTokReport';
import CampaignTikTok from '@/components/campaigns-page/CampaignTikTok';
import CampaignYoutube from '@/components/campaigns-page/CampaignYoutube';
import CampaignSummaryCard from '@/components/cards/CampaignSummaryCard';
import Loading from '@/components/utility/Loading';
import UserHeader from '@/components/utility/navigation/UserHeader';
import TikTokPostTemplate from '@/components/utility/social-media-templates/TikTokPostTemplate';
import CampaignStatus from '@/components/utility/statuses/CampaignStatus';
import { tikTokAgeRangesString } from '@/constants/Advert';
import usePagedAdverts from '@/hooks/adverts/usePagedAdverts';
import useAccountContext from '@/hooks/context/useAccountContext';
import useSnackbarContext from '@/hooks/context/useSnackbarContext';
import useTikTokAd from '@/hooks/tiktok/useTikTokAd';
import useTikTokAdVideo from '@/hooks/tiktok/useTikTokAdVideo';
import TikTokAPI from '@/network/TikTokAPI';
import { handleApiError } from '@/utility/api';

const CampaignTikTokPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { refetchPagedAdverts } = usePagedAdverts({
    status: '',
    offset: 0,
    limit: 10,
  });

  const { t } = useTranslation();

  const { accountId } = useAccountContext();
  const { dispatchSnackbar } = useSnackbarContext();

  const [value, setValue] = useState<number>(0);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isUpdateCampaignDisabled, setIsUpdateCampaignDisabled] = useState<boolean>(false);

  const advertId = useMemo(() => params.slug, [params]);

  const { tikTokAd, tikTokAdIsLoading, refetchTikTokAd } = useTikTokAd({
    advertId,
  });
  const { tikTokAdVideo, tikTokAdVideoIsLoading } = useTikTokAdVideo({
    advertiserId: tikTokAd?.metadata.advertiserId,
    identityId: tikTokAd?.metadata.identityId,
    tikTokVideoId: tikTokAd?.metadata.tikTokItemId,
  });

  const campaignSummary = useMemo(() => {
    if (!tikTokAd) return [];

    return [
      {
        name: 'CAMPAIGNS-PAGE.GOAL',
        value: tikTokAd.summary.goal ? tikTokAd.summary.goal.replace('-', ' ') : 'N/A',
      },
      {
        name: 'CAMPAIGNS-PAGE.START-DATE',
        value: `${format(new Date(tikTokAd.summary.startTime), 'dd/MM/yy')} at ${format(new Date(tikTokAd.summary.startTime), 'HH:mm b')}`,
      },
      {
        name: 'CAMPAIGNS-PAGE.END-DATE',
        value: `${format(new Date(tikTokAd.summary.endTime), 'dd/MM/yy')} at ${format(new Date(tikTokAd.summary.endTime), 'HH:mm b')}`,
      },
      {
        name: 'CAMPAIGNS-PAGE.BUDGET',
        value: tikTokAd.summary.budget
          ? `${getSymbolFromCurrency(tikTokAd.summary.priceLocale ?? 'GBP')}${tikTokAd.summary.budget}`
          : 'N/A',
      },
      {
        name: 'CAMPAIGNS-PAGE.AUDIENCE',
        value: `${t(tikTokAd.summary.gender)}, ${tikTokAd.summary.ageGroups.map((value) => tikTokAgeRangesString[value]).join(', ')}`,
      },
      {
        name: 'CAMPAIGNS-PAGE.LOCATIONS',
        value: tikTokAd.summary.locations.map((value) => value.name).join(', '),
      },
      {
        name: 'CAMPAIGNS-PAGE.INTERESTS',
        value: tikTokAd.summary.interests.map((value) => value.name).join(', '),
      },
      {
        name: 'CAMPAIGNS-PAGE.PLATFORM',
        value: tikTokAd.summary.platforms.join(', '),
      },
    ];
  }, [t, tikTokAd]);

  const updateTikTokCampaignStatus = useCallback(
    async (status: string) => {
      try {
        if (!accountId || !tikTokAd) return;

        await TikTokAPI.updateTikTokCampaign({
          accountId,
          advertId: tikTokAd.id,
          data: { status },
        });
        await refetchTikTokAd();
        await refetchPagedAdverts();
      } catch (error: unknown) {
        if (tikTokAd) {
          await refetchTikTokAd();
          await refetchPagedAdverts();
        }
        handleApiError({ error, dispatchSnackbar });
      } finally {
        setDialogOpen(false);
        setIsUpdateCampaignDisabled(false);
      }
    },
    [accountId, dispatchSnackbar, refetchPagedAdverts, refetchTikTokAd, tikTokAd]
  );

  return (
    <div data-testid="campaigns-playlist-pitch-page" className="page-content">
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
      >
        <DialogTitle>{t('CAMPAIGNS-PAGE.EDIT-CAMPAIGN')}</DialogTitle>
        <DialogContent>{t('CAMPAIGNS-PAGE.EDIT-CAMPAIGN-DESCRIPTION')}</DialogContent>
        <DialogActions>
          <Button
            disabled={isUpdateCampaignDisabled || tikTokAd?.status === 'active'}
            onClick={() => {
              setIsUpdateCampaignDisabled(true);
              updateTikTokCampaignStatus('active');
            }}
          >
            {t('COMMON.ACTIVATE')}
          </Button>
          <Button
            disabled={isUpdateCampaignDisabled || tikTokAd?.status === 'paused'}
            onClick={() => {
              setIsUpdateCampaignDisabled(true);
              updateTikTokCampaignStatus('paused');
            }}
          >
            {t('COMMON.PAUSE')}
          </Button>
          <Button
            disabled={isUpdateCampaignDisabled || tikTokAd?.status === 'archived'}
            onClick={() => {
              setIsUpdateCampaignDisabled(true);
              updateTikTokCampaignStatus('archived');
            }}
          >
            {t('COMMON.ARCHIVE')}
          </Button>
        </DialogActions>
      </Dialog>
      <UserHeader title="PAGE-TITLES.CAMPAIGNS" />
      <div className="d-flex jc-space-between mt16">
        <div className="d-flex jc-start">
          <Button
            className="ml0 btn-back"
            onClick={() => {
              navigate('/social-ads');
            }}
          >
            <Icon>chevron_left</Icon>
            <p className="pt2 pl4">{t('COMMON.BACK')}</p>
          </Button>
        </div>
        <div>
          <Button
            className="mr0 btn-back"
            onClick={() => {
              setDialogOpen(true);
            }}
          >
            <Icon>more_horiz</Icon>
          </Button>
        </div>
      </div>
      {tikTokAdIsLoading && (
        <div className="centered-loading mt48">
          <Loading />
        </div>
      )}
      {!tikTokAdIsLoading && (
        <div>
          <div className="card mt16 playlist-pitch-title-card">
            {!tikTokAdVideoIsLoading && (
              <div
                className="pos-abs"
                style={{
                  backgroundImage: `
                    url(${tikTokAdVideo?.video_info.poster_url || '/images/logos/social-media-icon.svg'})
                  `,
                  borderRadius: '12px 12px 12px 12px',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: '-50%',
                  height: '180px',
                  width: '100%',
                }}
              ></div>
            )}
            <div className="d-flex inner-titles social-media">
              <img src={tikTokAdVideo?.video_info.poster_url || '/images/logos/social-media-icon.svg'} alt="" />
              <div className="pl16 d-flex flex-d-col gap8 mt-auto mb-auto">
                <h3>{tikTokAd?.name}</h3>
                <p className="text-faded small">
                  {`Started on ${tikTokAd?.summary?.startTime && format(new Date(tikTokAd?.summary?.startTime), 'dd/MM/yy')}`}
                </p>
                <CampaignStatus status={tikTokAd?.status || ''} />
              </div>
            </div>
          </div>
          <div className="mt20 card">
            <Box
              sx={{
                width: '100%',
                marginBottom: '8px',
              }}
            >
              <Tabs value={value} onChange={(_, newValue) => setValue(newValue)}>
                <Tab label={t('PLAYLISTING.SUMMARY')} />
                <Tab label={t('COMMON.PERFORMANCE')} />
              </Tabs>
            </Box>
            {value === 0 && (
              <div className="d-flex gap20 flex-wrap">
                <div className="flex-w50p w100p-lg-down">
                  {campaignSummary && <CampaignSummaryCard summary={campaignSummary} />}
                </div>
                <div className="flex-w50p w100p-lg-down">
                  <div className="ml-auto mr-auto text-center">
                    <h3 className="mt20-lg-down ">Preview your ad</h3>
                    <p className="text-faded mt10 mb20">See what your ad looks like on different platforms</p>
                    {tikTokAdVideo && <TikTokPostTemplate postDetails={tikTokAdVideo} isOverlay={true} />}
                  </div>
                </div>
              </div>
            )}
            {value === 1 && (
              <div className="w100p">
                {tikTokAd && <CampaignSocialMediaTikTokReport campaignId={tikTokAd?.id} />}
                {tikTokAd?.summary.goal === 'more-streams' && (
                  <CampaignSpotify startTime={tikTokAd.summary.startTime} />
                )}
                {tikTokAd?.summary.goal === 'spotify-followers' && (
                  <CampaignSpotify startTime={tikTokAd.summary.startTime} />
                )}
                {tikTokAd?.summary.goal === 'instagram-followers' && (
                  <CampaignInstagram startTime={tikTokAd.summary.startTime} />
                )}
                {tikTokAd?.summary.goal === 'facebook-likes' && (
                  <CampaignFacebook startTime={tikTokAd.summary.startTime} />
                )}
                {tikTokAd?.summary.goal === 'tiktok-followers' && (
                  <CampaignTikTok startTime={tikTokAd.summary.startTime} />
                )}
                {tikTokAd?.summary.goal === 'youtube-views' && (
                  <CampaignYoutube startTime={tikTokAd.summary.startTime} />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignTikTokPage;
